"use client";
import { fontLoader } from "@/public/assets";
import "../../../styles/loading/loading.scss";
import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("react-lottie-player"));

export default function page() {
  return (
    <div className={"loader-image-wrap"}>
      <Lottie
        loop
        animationData={fontLoader}
        play
        className={"loader-image-main"}
      />
    </div>
  );
}
